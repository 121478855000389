import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
const Collaborate = () => {
  const { t } = useTranslation()
  const designer_list = t('page_home_section_6_designer_list', {
    returnObjects: true,
  })

  return (
    <section className="collaborate  container-blue">
      <div className="collaborate__container">
        <h2 className="collaborate__title">{t('page_home_section_6_title')}</h2>
        <div className="collaborate__content">
          <div className="collaborate__card">
            <div className="collaborate__card--content">
              <h3 className=" h2-style collaborate__card--title">
                {t('page_home_section_6_designer_title')}
              </h3>
              <p className="collaborate__card--text  font-medium h5-style">
                {t('page_home_section_6_designer_descr')}
              </p>
              <ul className="collaborate__card--list">
                {designer_list.map((list) => (
                  <li className=" h5-style  font-medium">{list}</li>
                ))}
              </ul>
              <div className="collaborate__card--btn ">
                <Link to={'/collaborate'} className="btn btn-primary">
                  {t('page_home_section_6_btn')}
                </Link>
              </div>
            </div>
          </div>

          <div className="collaborate__card">
            <div className="collaborate__card--content">
              <h3 className="h2-style collaborate__card--title blue">
                {t('page_home_section_6_dev_title')}
              </h3>
              <p className="collaborate__card--text  font-medium h5-style">
                {t('page_home_section_6_dev_descr')}
              </p>
              <div className="collaborate__card--btn ">
                <Link
                  to={'/collaborate/?dev=true'}
                  className="collaborate__card--btn btn btn-primary"
                >
                  {t('page_home_section_6_btn')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="collaborate__footer container "
          style={{ margin: 'auto' }}
        >
          <div className="collaborate__footer_buttons">
            <Link
              to={'/collaborate'}
              className="collaborate__footer_buttons  btn btn-primary"
            >
              {t('page_home_section_6_btn')}
            </Link>
          </div>
          <div className="collaborate__footer_buttons">
            <Link
              to={'/collaborate/?dev=true'}
              className="collaborate__footer_buttons  btn btn-primary"
            >
              {t('page_home_section_6_btn')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Collaborate
